import { gql } from "apollo-boost";

export const searchLeadsAndAgents = gql`
  query searchLeadsAndAgents(
    $query: String!
    $type: SearchType
    $limit: Int
    $crmOwner: ID
  ) {
    searchLeadsAndAgents(
      query: $query
      type: $type
      limit: $limit
      crmOwner: $crmOwner
    ) {
      __typename
      ... on Agent {
        id
        individualId
        displayName
        firstName
        lastName
        middleName
        nickName
        prefix
        suffix
        referredBy
        sex
        birthDate
        description
        doNotContact
        ssn
        deleted
        status
        createDate
        # lastModifiedDate
        version
        createdById
        # lastModifiedById
        mssCcExpirationDate
        mssEnrollmentDate
        mssLogin
        mssStatus
        npn
        onboardDate
        originalContractedDate
        residentState
        residentLicenseNumber
        agentOwnerId
        eoExpirationDate
        marketingUnsubscribe
        reportAlias
        principalType
        mssPlanYear
        agentGroupId
        externalId
        carrierHierarchiesExternal {
          totalCount
          data {
            id
            agentId
            carrierName
            # acStatus
            isCorporation
            companyName
            taxId
            # note
            # carrierLabel
            # agentGroupName
            # overrideAgreement
            agentHierarchyExternal {
              carrierId
              id
              agentId
              agentName
              level
              degreesFromBerwick
              isInactive
            }
          }
        }
        contracts {
          totalCount
          data {
            id
            agentId
            carrierName
            carrierLabel
            isCorp
            contractStatus
            statusDate
            effectiveDate
            submittedToCarrier
            state
            writingNumber
            productName
          }
        }
        dashboardEnabled
        contacts {
          data {
            id
            address1
            address2
            address3
            city
            country
            county
            postalCode
            state
            description
            email
            faxNumber
            firstName
            lastName
            mobilePhone
            phone
            status
            createDate
            deleted
            # lastModifiedDate
            type
            version
            workPhone
            individualId
            createdById
          }
        }
        agentGroups {
          data {
            agentGroupId
            description
            name
            version
          }
        }
        crmOwner {
          id
          firstName
          lastName
          displayName
        }
      }
      ... on Lead {
        id
        individualId
        displayName
        firstName
        lastName
        middleName
        nickName
        prefix
        suffix
        referredBy
        sex
        status
        createDate
        # lastModifiedDate
        description
        birthDate
        doNotContact
        ssn
        deleted
        createdById
        # lastModifiedById
        agencyName
        agentDescription
        leadDate
        priority
        rating
        version
        crmOwnerId
        npn
        contractingAs
        agencyNpn
        residentState
        licenseNumber
        uplineName
        uplineWritingNumber
        notes
        prospectiveCarriers
        numberOfAgents
        timeline
        areas
        leadSourceId
        leadSourceCode
        contacts {
          data {
            id
            address1
            address2
            address3
            city
            country
            county
            postalCode
            state
            description
            email
            faxNumber
            firstName
            lastName
            mobilePhone
            phone
            status
            createDate
            deleted
            # lastModifiedDate
            type
            version
            workPhone
            individualId
            createdById
          }
        }
        leadSources {
          data {
            leadSourceId
            name
          }
        }
        contractRequests {
          data {
            id
            version
            leadId
            carrierId
            requestLevel
            statesRequested
            products
            directUpline
            contractingAs
            deleted
            createDate
            # lastModifiedDate
            createdById
            # lastModifiedById
            sentDate
            carrier {
              id
              name
              status
            }
          }
        }
        crmOwner {
          id
          firstName
          lastName
          displayName
        }
      }
    }
  }
`;

export const getTicketsByIdForContractNotes = gql`
  query getTicketsByIdForContractNotes($id: ID!) {
    getTicketsByIdForContractNotes(id: $id) {
      id
      title
      ticketInboxId
      # lastModifiedDate
      ticketInbox {
        accountAddress
      }
    }
  }
`;
export const articlesByTicketId = gql`
  query articlesByTicketId($id: ID!) {
    articlesByTicketId(id: $id) {
      data {
        __typename
        ... on TicketResponse {
          id
          body
          uniqueBody
          createDate
          createdById
          sentStatus
          createdBy {
            id
            displayName
          }
          from
          to
          subject
          encrypted
          bcc
          cc
          ticketId
        }
        ... on TicketComment {
          id
          body
          createDate
          createdById
          createdBy {
            id
            displayName
          }
          ticketId
        }
      }
    }
  }
`;
export const getCRMOwners = gql`
  query crmOwners($paging: PagingInput) {
    crmOwners(page: $paging) {
      data {
        id
        email
        firstName
        lastName
        status
        displayName
        recruiter
        username
        agentgroup
      }
    }
  }
`;

export const sendEmailApi = gql`
  mutation sendEmailApi(
    $from: String!
    $to: [String]!
    $cc: [String]
    $bcc: [String]
    # $replyTo: String
    $subject: String
    $body: String
    $attachments: [EmailAttachment]
    $save: Boolean
  ) {
    sendEmailApi(
      from: $from
      to: $to
      cc: $cc
      bcc: $bcc
      # replyTo: $replyTo
      subject: $subject
      body: $body
      attachments: $attachments
      save: $save
    ) {
      success
    }
  }
`;

export const createLead = gql`
  mutation createLead($info: LeadInfo!) {
    createLead(info: $info) {
      id
      individualId
      displayName
      firstName
      lastName
      middleName
      nickName
      prefix
      suffix
      referredBy
      sex
      status
      createDate
      # lastModifiedDate
      description
      birthDate
      doNotContact
      ssn
      deleted
      createdById
      # lastModifiedById
      agencyName
      agentDescription
      leadDate
      priority
      rating
      version
      crmOwnerId
      npn
      contractingAs
      agencyNpn
      residentState
      licenseNumber
      uplineName
      uplineWritingNumber
      notes
      prospectiveCarriers
      numberOfAgents
      timeline
      areas
      leadSourceId
      leadSourceCode
      contacts {
        totalCount
        data {
          id
          address1
          address2
          address3
          city
          country
          county
          postalCode
          state
          description
          email
          faxNumber
          firstName
          lastName
          mobilePhone
          phone
          status
          createDate
          deleted
          # lastModifiedDate
          type
          version
          workPhone
          individualId
          createdById
        }
      }
      leadSources {
        data {
          leadSourceId
          name
        }
      }
      contractRequests {
        data {
          id
          version
          leadId
          carrierId
          requestLevel
          statesRequested
          products
          directUpline
          contractingAs
          deleted
          createDate
          # lastModifiedDate
          createdById
          # lastModifiedById
          sentDate
          carrier {
            id
            name
            status
          }
        }
      }
      crmOwner {
        id
        firstName
        lastName
        displayName
      }
    }
  }
`;

export const updateLead = gql`
  mutation updateLead($info: LeadInfo!, $id: ID!, $contactId: ID) {
    updateLead(info: $info, id: $id, contactId: $contactId) {
      id
      individualId
      displayName
      firstName
      lastName
      middleName
      nickName
      prefix
      suffix
      referredBy
      sex
      status
      createDate
      # lastModifiedDate
      description
      birthDate
      doNotContact
      ssn
      deleted
      createdById
      # lastModifiedById
      agencyName
      agentDescription
      leadDate
      priority
      rating
      version
      crmOwnerId
      npn
      contractingAs
      agencyNpn
      residentState
      licenseNumber
      uplineName
      uplineWritingNumber
      notes
      prospectiveCarriers
      numberOfAgents
      timeline
      areas
      leadSourceId
      leadSourceCode
      contacts {
        totalCount
        data {
          id
          address1
          address2
          address3
          city
          country
          county
          postalCode
          state
          description
          email
          faxNumber
          firstName
          lastName
          mobilePhone
          phone
          status
          createDate
          deleted
          # lastModifiedDate
          type
          version
          workPhone
          individualId
          createdById
        }
      }
      leadSources {
        data {
          leadSourceId
          name
        }
      }
      contractRequests {
        data {
          id
          version
          leadId
          carrierId
          requestLevel
          statesRequested
          products
          directUpline
          contractingAs
          deleted
          createDate
          # lastModifiedDate
          createdById
          # lastModifiedById
          sentDate
          carrier {
            id
            name
            status
          }
        }
      }
      crmOwner {
        id
        firstName
        lastName
        displayName
      }
    }
  }
`;

export const updateAgent = gql`
  mutation updateAgent($info: AgentInfo!, $id: ID!, $contactId: ID) {
    updateAgent(info: $info, id: $id, contactId: $contactId) {
      id
      individualId
      displayName
      firstName
      lastName
      middleName
      nickName
      prefix
      suffix
      referredBy
      sex
      birthDate
      description
      doNotContact
      ssn
      deleted
      status
      createDate
      # lastModifiedDate
      version
      createdById
      # lastModifiedById
      mssCcExpirationDate
      mssEnrollmentDate
      mssLogin
      mssStatus
      npn
      onboardDate
      originalContractedDate
      residentLicenseNumber
      agentOwnerId
      eoExpirationDate
      marketingUnsubscribe
      reportAlias
      principalType
      mssPlanYear
      agentGroupId
      externalId
      carrierHierarchiesExternal {
        totalCount
        data {
          id
          agentId
          carrierName
          # acStatus
          isCorporation
          companyName
          taxId
          note
          carrierLabel
          agentGroupName
          # overrideAgreement
          agentHierarchyExternal {
            carrierId
            id
            agentId
            agentName
            level
            degreesFromBerwick
            isInactive
          }
        }
      }
      contracts {
        totalCount
        data {
          id
          agentId
          carrierName
          carrierLabel
          isCorp
          contractStatus
          statusDate
          effectiveDate
          submittedToCarrier
          state
          writingNumber
          productName
        }
      }
      dashboardEnabled
      contacts {
        data {
          id
          address1
          address2
          address3
          city
          country
          county
          postalCode
          state
          description
          email
          faxNumber
          firstName
          lastName
          mobilePhone
          phone
          status
          createDate
          deleted
          # lastModifiedDate
          type
          version
          workPhone
          individualId
          createdById
        }
      }
      agentGroups {
        data {
          agentGroupId
          description
          name
          version
        }
      }
      crmOwner {
        id
        firstName
        lastName
        displayName
      }
    }
  }
`;

export const getLeadSources = gql`
  query getLeadSources {
    getLeadSources {
      leadSourceId
      name
    }
  }
`;

export const createNoteComment = gql`
  mutation createNoteComment($noteId: ID!, $info: NoteCommentInput!) {
    createNoteComment(noteId: $noteId, info: $info) {
      __typename
      id
      confidential
      followUpDate
      notificationDate
      priority
      recurringFollowUpInterval
      status
      deleted
      timeSpent
      title
      category {
        id
        name
      }
      noteCategoryId
      assignedToId
      assignedTo {
        id
        email
        firstName
        lastName
        status
        displayName
      }
      createDate
      # lastModifiedDate
      createdById
      createdBy {
        id
        email
        firstName
        lastName
        status
        displayName
      }
      # lastModifiedById
      # lastModifiedBy {
      #   id
      #   email
      #   firstName
      #   lastName
      #   status
      #   displayName
      # }
      comments {
        totalCount
        data {
          id
          comment
          noteId
          actor
          createDate
          # lastModifiedDate
          createdById
          createdBy {
            id
            email
            firstName
            lastName
            status
            displayName
          }
          # lastModifiedById
          # lastModifiedBy {
          #   id
          #   email
          #   firstName
          #   lastName
          #   status
          #   displayName
          # }
          deleted
        }
        pagingInfo {
          current
          total
        }
      }
      files {
        totalCount
        data {
          id
          createDate
          # lastModifiedDate
          createdById
          # lastModifiedById
          name
          size
          category
          isTemplate
          mimeType
        }
      }
    }
  }
`;

export const createNoteV2 = gql`
  mutation createNoteV2($info: CombinedNoteInput!) {
    createNoteV2(info: $info) {
      __typename
      id
      confidential
      followUpDate
      notificationDate
      priority
      recurringFollowUpInterval
      status
      deleted
      timeSpent
      title
      category {
        id
        name
      }
      noteCategoryId
      assignedToId
      assignedTo {
        id
        email
        firstName
        lastName
        status
        displayName
      }
      createDate
      # lastModifiedDate
      createdById
      createdBy {
        id
        email
        firstName
        lastName
        status
        displayName
      }
      # lastModifiedById
      # lastModifiedBy {
      #   id
      #   email
      #   firstName
      #   lastName
      #   status
      #   displayName
      # }
      comments {
        totalCount
        data {
          id
          comment
          noteId
          actor
          createDate
          # lastModifiedDate
          createdById
          createdBy {
            id
            email
            firstName
            lastName
            status
            displayName
          }
          # lastModifiedById
          # lastModifiedBy {
          #   id
          #   email
          #   firstName
          #   lastName
          #   status
          #   displayName
          # }
          deleted
        }
        pagingInfo {
          current
          total
        }
      }
      files {
        totalCount
        data {
          id
          createDate
          # lastModifiedDate
          createdById
          # lastModifiedById
          name
          size
          category
          isTemplate
          mimeType
        }
      }
    }
  }
`;

export const saveContactAfterEdit = gql`
  mutation saveContactAfterEdit($info: ContactInput!) {
    saveContactAfterEdit(info: $info) {
      id
      address1
      address2
      address3
      city
      country
      county
      postalCode
      state
      description
      email
      faxNumber
      mobilePhone
      phone
      status
      createDate
      deleted
      # lastModifiedDate
      type
      version
      workPhone
      individualId
      createdById
      # lastModifiedById
    }
  }
`;
export const agent = gql`
  query agent($id: ID!) {
    agent(id: $id) {
      id
      individualId
      displayName
      firstName
      lastName
      middleName
      nickName
      prefix
      suffix
      referredBy
      sex
      birthDate
      description
      doNotContact
      ssn
      deleted
      status
      createDate
      # lastModifiedDate
      version
      createdById
      # lastModifiedById
      mssCcExpirationDate
      mssEnrollmentDate
      mssLogin
      mssStatus
      npn
      onboardDate
      originalContractedDate
      residentLicenseNumber
      agentOwnerId
      eoExpirationDate
      marketingUnsubscribe
      reportAlias
      principalType
      mssPlanYear
      agentGroupId
      externalId
      carrierHierarchiesExternal {
        totalCount
        data {
          id
          agentId
          carrierName
          # acStatus
          isCorporation
          companyName
          taxId
          note
          carrierLabel
          agentGroupName
          # overrideAgreement
          agentHierarchyExternal {
            carrierId
            id
            agentId
            agentName
            level
            degreesFromBerwick
            isInactive
          }
        }
      }
      contracts {
        totalCount
        data {
          id
          agentId
          carrierName
          carrierLabel
          isCorp
          contractStatus
          statusDate
          effectiveDate
          submittedToCarrier
          state
          writingNumber
          productName
        }
      }
      dashboardEnabled
      contacts {
        data {
          id
          address1
          address2
          address3
          city
          country
          county
          postalCode
          state
          description
          email
          faxNumber
          firstName
          lastName
          mobilePhone
          phone
          status
          createDate
          deleted
          # lastModifiedDate
          type
          version
          workPhone
          individualId
          createdById
        }
      }
      agentGroups {
        data {
          agentGroupId
          description
          name
          version
        }
      }
      crmOwner {
        id
        firstName
        lastName
        displayName
      }
    }
  }
`;

export const getAgency = gql`
  query getAgency {
    agencies {
      id
      agencyName
    }
  }
`;

export const reportDataByAgencyId = gql`
  query reportDataByAgencyId($agencyId: String, $year: String) {
    reportDataByAgencyId(agencyId: $agencyId, year: $year) {
      carrierId
      carrierName
      companyName
      agentname
      agentId
      writingNumber
      hlevel
      uplines
      effectiveDate
      state
      county
      maProduction
      medsuppProduction
      pdpProduction
      maGrandtotal
      medsuppGrandtotal
      pdpGrandtotal
      recordDate
      npn
    }
  }
`;

export const createContact = gql`
  mutation createContact($info: CreateContactInput!) {
    createContact(info: $info) {
      id
      address1
      address2
      address3
      city
      country
      county
      postalCode
      state
      description
      email
      faxNumber
      mobilePhone
      phone
      status
      createDate
      deleted
      # lastModifiedDate
      type
      version
      workPhone
      individualId
      createdById
      # lastModifiedById
    }
  }
`;

export const reportByOwnerIdAndYear = gql`
  query reportByOwnerIdAndYear($id: ID!, $yr: String) {
    reportByOwnerIdAndYear(id: $id, yr: $yr) {
      carrierName
      agencyId
      agencyName
      ma
      medsupp
      pdp
      agencyTotalMa
      agencyTotalMedsupp
      agencyTotalPdp
      agencyOwnerTotalMa
      agencyOwnerTotalMedsupp
      agencyOwnerTotalPdp
    }
  }
`;

export const createLeadContractRequest = gql`
  mutation createLeadContractRequest($info: ContractRequestInput!) {
    createLeadContractRequest(info: $info) {
      id
      carrier {
        id
        name
        status
      }
      version
      leadId
      carrierId
      requestLevel
      statesRequested
      products
      directUpline
      contractingAs
      deleted
      createDate
      # lastModifiedDate
      createdById
      # lastModifiedById
      sentDate
    }
  }
`;

export const eodReport = gql`
  query eodReport($date: String) {
    eodReport(date: $date) {
      carrier
      firstName
      lastName
      companyName
      contractStatus
      writingNumbers
      agentOwner
      carrierLabel
      uplines
    }
  }
`;

export const contractRequestsByAgentId = gql`
  query contractRequestsByAgentId($agentId: String!) {
    contractRequestsByAgentId(agentId: $agentId) {
      id
      carrierName
      states
      status
    }
  }
`;
