import React from "react";
import { Accordion, AccordionSummary, Divider, Grid } from "@mui/material";

const PendingRequestList = (props) => {
  const { classes, pendingRequests } = props;
  return (
    <Grid item xs={12} sm={6}>
      {pendingRequests.map((request, i) => {
        return (
          <Accordion disableGutters key={`${request.id} - ${i}`}>
            <AccordionSummary>
              <Grid container>
                {request.carrierName} - {request.states}
                <Grid item xs={12}>
                  <span
                    style={{
                      color:
                        request.status === "submitted"
                          ? "limegreen"
                          : request.status === "pending"
                          ? "#0094ff"
                          : "#e2772c",
                      fontSize: ".75em",
                      fontWeight: 600,
                    }}
                  >
                    {request.status.toUpperCase()}
                  </span>
                </Grid>
              </Grid>
            </AccordionSummary>
            <Divider />
          </Accordion>
        );
      })}
    </Grid>
  );
};

export default PendingRequestList;
