import React, { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Tabs,
  Tab,
  createTheme,
  ThemeProvider,
  useMediaQuery,
} from "@mui/material";
import ContractList from "../components/pages/agents/agentsComponents/ContractList";
import RtsCertsTable from "../components/pages/agents/agentsComponents/RtsCertsTable";
import InnerLoading from "./InnerLoading";
import UtilityCard from "../components/ui-components/UtilityCard";
import PendingRequestList from "../components/pages/agents/agentsComponents/PendingRequestList";

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.2rem",
  "@media (max-width:400px)": {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.5rem",
  },
};

const ContractRequestPage = (props) => {
  const mobileScreen = useMediaQuery("(max-width:600px)");

  const {
    classes,
    clickedAgent,
    certsData,
    certsLoading,
    pendingRequests,
    agentStore,
  } = props;
  const [pageValue, setPageValue] = useState(0);
  const handlePageChange = (e, newValue) => {
    setPageValue(newValue);
  };

  useEffect(() => {
    async function loadPendingRequests() {
      await agentStore.loadPendingRequests();
    }
    loadPendingRequests();
  }, [agentStore, pendingRequests]);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      className={classes.expansionGrid}
    >
      <Grid container item justifyContent="center" xs={12} spacing={1}>
        <Grid item xs={12}>
          <Tabs
            onChange={handlePageChange}
            value={pageValue}
            centered
            variant={mobileScreen ? "fullWidth" : false}
          >
            <Tab
              style={{ color: pageValue === 2 && "grey" }}
              label={
                <ThemeProvider theme={theme}>
                  <Typography variant="h3">Pending Requests</Typography>
                </ThemeProvider>
              }
            />
            <Tab
              style={{
                color: pageValue === 1 && "grey",
              }}
              label={
                <ThemeProvider theme={theme}>
                  <Typography variant="h3">Contracts</Typography>
                </ThemeProvider>
              }
            />
            <Tab
              style={{
                color: pageValue === 0 && "grey",
              }}
              label={
                <ThemeProvider theme={theme}>
                  <Typography variant="h3">RTS Certs</Typography>
                </ThemeProvider>
              }
            />
          </Tabs>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          {pendingRequests.length > 0 && pageValue === 0 && (
            <PendingRequestList
              classes={classes}
              pendingRequests={pendingRequests}
            />
          )}
          {pendingRequests.length === 0 && pageValue === 0 && (
            <Grid container justifyContent="center">
              <Grid item style={{ margin: 40 }}>
                <UtilityCard>No Pending Requests To Show</UtilityCard>
              </Grid>
            </Grid>
          )}
          {pageValue === 1 &&
            (clickedAgent.contracts.data.length > 0 ? (
              <ContractList
                classes={classes}
                contracts={clickedAgent.contracts}
              />
            ) : (
              <Grid container justifyContent="center">
                <Grid item style={{ margin: 40 }}>
                  <UtilityCard>No Contracts To Show</UtilityCard>
                </Grid>
              </Grid>
            ))}
          {pageValue === 2 &&
            (certsLoading ? (
              <InnerLoading> Loading Rts Certs For This Agent!</InnerLoading>
            ) : certsData.length > 0 ? (
              <RtsCertsTable classes={classes} certsData={certsData} />
            ) : (
              <Grid container justifyContent="center">
                <Grid item style={{ margin: 40 }}>
                  <UtilityCard>No Certs Data To Show</UtilityCard>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ContractRequestPage;
