import React, { useState } from "react";
import {
  Button,
  Grid,
  Dialog,
  Typography,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import Swal from "sweetalert2";
import UtilityLoading from "./UtilityLoading";
import { observer } from "mobx-react";

const ValidateModal = observer((props) => {
  const {
    openValidationModal,
    agentData,
    handleCancelSubmit,
    agentInfoSubmitted,
    existingContracts,
    notesValue,
    setAgentInfoSubmitted,
    sendContractRequest,
    clearContractModal,
    swalWithDialogAdjustment,
    setOpenValidationModal,
    mQuery,
    updateFromContractRequest,
    agentId,
    isAsCorp,
    phone,
    email,
    agentStore,
  } = props;
  const [sendEmailLoading, setSendEmailLoading] = useState(false);

  //Need to bring in through the agent for tohClass -
  //in order to enable admin login to use agents tohClass

  const validated = async () => {
    setAgentInfoSubmitted(true);
    setSendEmailLoading(true);
    let emailResult = await sendContractRequest(
      agentData,
      existingContracts,
      notesValue,
      agentId,
      isAsCorp,
      phone,
      email
    );
    // Uncomment and fix if needed in the future - scaffolding is in place but not ready.
    // switch (agentData.__typename) {
    //   case "Agent":
    //     updateFromContractRequest({
    //       birthDate: agentData.birthDate,
    //     });
    //     break;
    //   default:
    //     break;
    // }

    if (emailResult === "success") {
      setOpenValidationModal(false);
      setAgentInfoSubmitted(false);
      setSendEmailLoading(false);
      clearContractModal();
      await agentStore.loadPendingRequests();

      swalWithDialogAdjustment.fire({
        title: "Contract requests submitted to Berwick",
        icon: "success",
      });
    } else {
      setOpenValidationModal(false);
      setAgentInfoSubmitted(false);
      setSendEmailLoading(false);
      clearContractModal();
      Swal.fire({
        title: "Oops!",
        text: emailResult,
        icon: "error",
        //TODO: update the styling since danger Mode isn't a thing anymore
        // dangerMode: true,
      });
    }
  };

  return (
    <>
      <Dialog
        onClose={(e, reason) =>
          reason !== "backdropClick" && clearContractModal()
        }
        open={openValidationModal}
        maxWidth="sm"
        fullWidth
        fullScreen={mQuery}
      >
        <DialogTitle>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <ReportProblemOutlinedIcon
                style={{
                  fontSize: "7rem",
                  color: "#f7c357",
                }}
              />
            </Grid>
            <Grid item>
              <Typography variant="h6">
                Are you sure you would like to submit this request?
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent style={{ marginTop: 30 }}>
          <Typography variant="body1">
            Contract Requests can only be modified or deleted by our Contracting
            Team. Please call Contracting if you have any questions or need
            assistance.
          </Typography>
        </DialogContent>
        <DialogActions
          style={{
            margin: 10,
          }}
        >
          <Grid container direction="row" justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                size="small"
                onClick={handleCancelSubmit}
                disabled={agentInfoSubmitted}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="small"
                onClick={validated}
                disabled={agentInfoSubmitted}
              >
                OK
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
      <UtilityLoading loadingState={sendEmailLoading}>
        Submitting Your Request...
      </UtilityLoading>
    </>
  );
});

export default ValidateModal;
